<template>
  <div>
    <div class="max-w-4xl mx-auto py-10 px-6 bg-white pt-24">
      <h1 class="text-2xl font-bold text-center mb-6">KYC Policy (Know Your Customer Policy)</h1>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">1. Introduction</h2>
        <p>The purpose of the KYC (Know Your Customer) Policy is to establish principles and procedures for user identification on the Like Me Fun platform. This policy is designed to prevent the use of the Like Me Fun service for illegal activities such as money laundering, terrorist financing, or identity theft.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">2. User Identification</h2>
        <p>To meet KYC requirements, Like Me Fun utilizes Telegram user data for identification. This includes, but is not limited to, using verified profile data from Telegram, including the user's name and phone number, and verifying the authenticity of information provided by users upon registration in Telegram.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">3. Record Keeping</h2>
        <p>Like Me Fun will maintain records of all identification information and transactions for a minimum period as required by applicable law. This information will be stored securely to protect against unauthorized access and data breaches.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">4. Monitoring and Reporting</h2>
        <p>Like Me Fun will monitor transactions for suspicious activities and has the right to report any transactions it deems suspicious to the relevant authorities without prior notice to the user.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">5. Compliance and Cooperation with Law Enforcement</h2>
        <p>Like Me Fun is committed to full compliance with all applicable laws and regulations related to anti-money laundering (AML) and counter-terrorism financing (CTF). Like Me Fun will cooperate with law enforcement and regulatory authorities in their efforts to combat such crimes.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">6. Amendments to the KYC Policy</h2>
        <p>Like Me Fun reserves the right to modify this KYC Policy at any time to ensure compliance with evolving legal frameworks and best practices. Users are encouraged to review this policy periodically.</p>
      </section>

      <section>
        <h2 class="text-xl font-semibold mb-3">7. Acceptance of the KYC Policy</h2>
        <p>By using the Like Me Fun platform and its services, users agree to comply with this KYC Policy and acknowledge that their failure to do so may result in the suspension or termination of their account.</p>
      </section>
    </div>
  </div>
</template>
<script setup>
</script>