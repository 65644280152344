<template>
  <div>
    <div class="max-w-4xl mx-auto py-10 px-6 bg-white pt-24">
      <h1 class="text-2xl font-bold text-center mb-6">Age Limit Notice</h1>

      <p class="mb-4">Dear Users!</p>

      <p class="mb-4">Our service, Like Me Fun, is strictly for individuals who are 18 years of age or older. By using this service, you confirm that you have reached the age of 18, and you fully understand and accept the responsibility to comply with all age restrictions.</p>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">1. Age Limit</h2>
        <p>Access to the Like Me Fun service is allowed only for individuals who have reached the age of majority (18 years or older), in accordance with the legislation of the user's country of residence. We reserve the right to request age verification at any time to ensure compliance with this condition.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">2. Responsibility</h2>
        <p>Users who provide false information about their age to gain access to the service may be immediately excluded from its use without the right to restore their account. We urge you to responsibly go through the registration process and use the service considering all age limit.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">3. Parental Control</h2>
        <p>Parents or guardians concerned that their children may access our service are encouraged to use parental control software and other monitoring methods to ensure the safety of children online.</p>
      </section>

      <p>By continuing to use the Like Me Fun service, you confirm your agreement with these age limit and commit to abide by them.</p>
    </div>
  </div>
</template>