<template>
  <div class="bg-white">
    <header id="top" class="absolute inset-x-0 top-0 z-50">
      <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div class="flex lg:flex-1">
          <a href="/" class="-m-1.5 p-1.5">
            <span class="sr-only">Like Me</span>
            <img class="h-8 w-auto" src="/img/logo.png" alt="" />
          </a>
        </div>
        <div class="flex lg:hidden">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="hidden lg:flex lg:gap-x-12">
          <a v-for="item in navigation" :key="item.name" :href="item.href" class="text-sm font-semibold leading-6 text-gray-900">{{ item.name }}</a>
        </div>
        <div class="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="#" class="text-sm font-semibold leading-6 text-pink-600">Launch bot <span aria-hidden="true">&rarr;</span></a>
        </div>
      </nav>
      <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
        <div class="fixed inset-0 z-10" />
        <DialogPanel class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div class="flex items-center justify-between">
            <a href="#" class="-m-1.5 p-1.5">
              <span class="sr-only">Like Me</span>
              <img class="h-8 w-auto" src="/img/logo.png" alt="" />
            </a>
            <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
              <span class="sr-only">Close menu</span>
              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/10">
              <div class="space-y-2 py-6">
                <a v-for="item in navigation" :key="item.name" :href="item.href" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.name }}</a>
              </div>
              <div class="py-6">
                <a href="https://t.me/likeme_fun_bot" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-pink-600 hover:bg-gray-50">
                  Launch bot
                </a>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
    <router-view></router-view>
    <footer class="bg-white">
      <div class="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav class="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
          <div v-for="item in footerNavigation.main" :key="item.name" class="pb-6">
            <a :href="item.href" class="text-sm leading-6 text-gray-600 hover:text-gray-900">{{ item.name }}</a>
          </div>
        </nav>
        <p class="mt-10 text-center text-xs leading-5 text-gray-500">&copy; 2024 Like Me Fun. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
import { Dialog, DialogPanel } from '@headlessui/vue'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'

export default {
  components: {
    Dialog,
    DialogPanel,
    Bars3Icon,
    XMarkIcon,
  },

  data() {
    return {
      navigation: [
        { name: 'Top', href: '/#top' },
        { name: 'Features', href: '/#features' },
        { name: 'Frequently asked questions', href: '/#faq' },
      ],

      footerNavigation: {
        main: [
          { name: 'Terms of Use', href: '/terms-of-use' },
          { name: 'KYC Policy', href: '/kyc-policy' },
          { name: 'Age Limit', href: '/age-limit' },
          { name: 'Purchase Terms', href: '/purchase-terms' }
        ],
      },

      mobileMenuOpen: false
    }
  }
}
</script>