<template>
  <div class="relative isolate pt-14">
    <svg class="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
      <defs>
        <pattern id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
          <path d="M100 200V.5M.5 .5H200" fill="none" />
        </pattern>
      </defs>
      <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
        <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z" stroke-width="0" />
      </svg>
      <rect width="100%" height="100%" stroke-width="0" fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
    </svg>
    <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
      <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
        <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          Telegram bot for finding dating
        </h1>
        <p class="mt-6 text-lg leading-8 text-gray-600">
          Just Like People and Get Likes in Return – Open the Door to Interesting Meetings and Bright Encounters, Starting Right Now!
        </p>
        <div class="mt-10 flex items-center gap-x-6">
          <a href="https://t.me/likeme_fun_bot" class="rounded-xl bg-pink-600 px-5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600">
            Start Like Me
          </a>
        </div>
      </div>
      <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
        <svg viewBox="0 0 366 729" role="img" class="mx-auto w-[22.875rem] max-w-full drop-shadow-xl">
          <title>App screenshot</title>
          <defs>
            <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
              <rect width="316" height="684" rx="36" />
            </clipPath>
          </defs>
          <path fill="#4B5563" d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z" />
          <path fill="#343E4E" d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z" />
          <foreignObject width="316" height="684" transform="translate(24 24)" clip-path="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)">
            <img src="/img/demo.png" alt="" />
          </foreignObject>
        </svg>
      </div>
    </div>
  </div>

  <div id="features" class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
    <div class="bg-pink-700 py-10 sm:py-16 rounded-3xl">
      <div class="mx-auto max-w-7xl px-6 lg:px-16">
        <div class="mx-auto max-w-2xl lg:mx-0">
          <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Features
          </h2>
          <p class="mt-6 text-lg leading-8 text-white">
            What a bot can do and why it can help you find dating
          </p>
        </div>
        <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            <div v-for="feature in features" :key="feature.name" class="flex flex-col">
              <dt class="text-base font-semibold leading-7 text-white">
                <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-white">
                  <component :is="feature.icon" class="h-6 w-6 text-pink-700" aria-hidden="true" />
                </div>
                {{ feature.name }}
              </dt>
              <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-pink-100">
                <p class="flex-auto">{{ feature.description }}</p>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>

  <div id="faq" class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
    <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
      <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
      <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
        <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
          <dt>
            <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900">
              <span class="text-base font-semibold leading-7">{{ faq.question }}</span>
              <span class="ml-6 flex h-7 items-center">
                  <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                  <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
                </span>
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" class="mt-2 pr-12">
            <p class="text-base leading-7 text-gray-600">{{ faq.answer }}</p>
          </DisclosurePanel>
        </Disclosure>
      </dl>
    </div>
  </div>
</template>

<script>
import { Dialog, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import {
  Bars3Icon,
  XMarkIcon,
  InboxIcon,
  TrashIcon,
  UsersIcon,
  MinusSmallIcon,
  PlusSmallIcon,
  UserCircleIcon,
  MapPinIcon,
  HeartIcon
} from '@heroicons/vue/24/outline'

export default {
  components: {
    Dialog,
    DialogPanel,
    Bars3Icon,
    XMarkIcon,
    InboxIcon,
    TrashIcon,
    UsersIcon,
    MinusSmallIcon,
    PlusSmallIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    UserCircleIcon,
    MapPinIcon,
    HeartIcon
  },

  data() {
    return {
      features: [
        {
          name: 'Profile',
          description: 'Possibility to upload a photo and fill out basic information about yourself (age, gender, interests).',
          href: '#',
          icon: UserCircleIcon,
        },
        {
          name: 'Search by location',
          description:
              'Automatic detection of the user\'s current location and selection of nearby profiles.',
          href: '#',
          icon: MapPinIcon,
        },
        {
          name: 'Like and matching system',
          description:
              'The ability to “like” a profile if you like it.\n' +
              'Notification system for mutual “likes” (matching).\n' +
              'Chat for communicating with “matches”.',
          href: '#',
          icon: HeartIcon,
        },
      ],

      faqs: [
        {
          question: "How does the search algorithm work?",
          answer: "The search works by location, search criteria, for example: gender, age, country.",
        },
        {
          question: 'How can I change my search settings?',
          answer: 'You can change your search settings by going to the Settings section. There you can set preferences such as age, gender, and others.'
        },
        {
          question: 'How do I communicate with someone I like?',
          answer: 'If you liked a user and received a reciprocal like, you will be able to send a message via chat. Go to the "Matches" section to see a list of users with whom you have mutual interest.'
        },
        {
          question: 'What should I do if I encounter unwanted behavior?',
          answer: 'You can block a user or report their behavior. Our team will review your complaint as soon as possible.'
        },
        {
          question: 'Is there a limit on the number of likes per day?',
          answer: 'In our bot there is a limit on the number of likes per day up to 10 if you do not have a VIP profile, otherwise there is no limit.'
        },
        {
          question: 'How does the bot protect my personal data?',
          answer: 'We attach great importance to the privacy and security of your data. Your personal information is protected to the latest security standards and we do not share your information with third parties without your consent.'
        }
      ],
    }
  }
}
</script>