<template>
  <div>
    <div class="max-w-4xl mx-auto py-10 px-6 bg-white pt-24">
      <h1 class="text-2xl font-bold text-center mb-6">Terms of Use for Like Me Fun</h1>
      <p class="mb-4"><strong>Effective Date:</strong> 2024-03-01</p>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">1. General Provisions</h2>
        <p>Like Me Fun provides a dating platform where users can like each other's profiles. If the liking is mutual, users are encouraged to start a conversation in Telegram.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">2. Start bot and create Account</h2>
        <p>To use Like Me Fun, users must launch the bot and create an account. Users are required to provide accurate and current information when registering and to keep it up to date.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">3. Like Limitations</h2>
        <p>Non-VIP users can like up to 10 profiles per day. To remove this limitation, users can purchase VIP status.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">4. VIP Status</h2>
        <p>VIP status offers additional features, including an unlimited number of likes. VIP status can be purchased with cryptocurrency (USDT, TON, BTC) via Telegram Wallet.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">5. Rights and Obligations of the Parties</h2>
        <p>Users agree not to use Like Me Fun for illegal activities, including the distribution of materials that infringe on third-party rights. Like Me Fun is not responsible for user actions but reserves the right to block or delete accounts for violating terms of use.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">6. Limitation of Liability</h2>
        <p>Like Me Fun is not liable for any direct, indirect, incidental, special damages, or losses resulting from the use or inability to use the service.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">7. Changes to Terms of Use</h2>
        <p>Like Me Fun reserves the right to change the Terms of Use at any time without prior notice. Users are required to periodically review the terms for changes.</p>
      </section>

      <section>
        <h2 class="text-xl font-semibold mb-3">8. Consent to Terms</h2>
        <p>By using Like Me Fun, you automatically agree to these terms.</p>
      </section>
    </div>
  </div>
</template>