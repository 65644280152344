import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import './index.css'

// Import your component files for the Terms of Use, KYC, Age Restriction, and Purchase Conditions pages
import Home from './views/Home.vue';
import Conditions from './views/Conditions.vue';
import Kyc from './views/Kyc.vue';
import AgeRestriction from './views/AgeRestriction.vue';
import PurchaseConditions from './views/PurchaseConditions.vue';

const router = createRouter({
    history: createWebHistory(), // Use history mode for cleaner URLs (optional: replace with createHashHistory() for hash-based routing)
    routes: [
        {
            path: '/', // Set the default route to your main page
            component: Home,
            meta: {
                title: 'Like Me Fun - Telegram bot for finding dating'
            }
        },
        {
            path: '/terms-of-use',
            component: Conditions,
            meta: {
                title: 'Terms of Use | Like Me Fun'
            }
        },
        {
            path: '/kyc-policy',
            component: Kyc,
            meta: {
                title: 'KYC Policy | Like Me Fun'
            }
        },
        {
            path: '/age-limit',
            component: AgeRestriction,
            meta: {
                title: 'Age Limit Notice | Like Me Fun'
            }
        },
        {
            path: '/purchase-terms',
            component: PurchaseConditions,
            meta: {
                title: 'Purchase Terms | Like Me Fun'
            }
        },
    ],
});

router.afterEach((to, from) => {
    console.log(from)

    document.title = to.meta.title || 'Like Me Fun';
});


const app = createApp(App);

app.use(router);

app.mount('#app')
