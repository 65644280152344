<template>
  <div>
    <div class="max-w-4xl mx-auto py-10 px-6 bg-white pt-24">
      <h1 class="text-2xl font-bold text-center mb-6">Purchase Terms</h1>

      <p class="mb-4"><strong>Welcome to Like Me Fun!</strong></p>

      <p class="mb-4">Before purchasing virtual currency on our platform, please carefully read the purchase terms. By buying Like Fun Coin and using them to obtain VIP status or send gifts to other users, you confirm your agreement with these terms.</p>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">1. Service Description</h2>
        <p>Like Me Fun offers users the opportunity to purchase virtual currency — Like Fun Coin, which can be used to acquire VIP status, granting access to additional features in the app, or to send gifts to other users.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">2. Payment Methods</h2>
        <p>Payment for Like Fun Coin is made using cryptocurrency (USDT, TON, BTC) through Telegram Wallet. Please familiarize yourself with the terms of use for the chosen cryptocurrency and Telegram Wallet before making a purchase.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">3. Refund Policy</h2>
        <p>In accordance with Like Me Fun policy, the purchase of Like Fun Coin is final and not subject to refunds. In case of technical problems with purchasing or using the currency, please contact support for assistance.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">4. Changes to Purchase Terms</h2>
        <p>Like Me Fun reserves the right to modify the Purchase Terms at any time without prior notice. By using the service after changes are made, you automatically agree to the updated terms.</p>
      </section>

      <section class="mb-5">
        <h2 class="text-xl font-semibold mb-3">5. Disclaimer of Liability</h2>
        <p>Like Me Fun is not liable for any losses or damages resulting from the use of Like Fun Coin. Users bear full responsibility for the safe and legal use of the virtual currency in accordance with applicable law.</p>
      </section>

      <p>By purchasing Like Fun Coin on Like Me Fun, you confirm that you have read these terms and fully agree with them.</p>
    </div>
  </div>
</template>